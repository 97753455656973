import request from '@/utils/request'

// 办事类型
export function WeGetMyActivityPage(query) {
    return request({
        url: '/PBActivity/WeGetMyActivityPage',
        method: 'get',
        params: query
    })
}

export function WeGetActivityDetail(query) {
    return request({
        url: '/PBActivity/WeGetActivityDetail',
        method: 'get',
        params: query
    })
}

export function WeEnrolActivity(data) {
    return request({
        url: 'PBActivity/WeEnrolActivity',
        method: 'post',
        data: data
    })
}